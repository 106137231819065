import React, {useState} from 'react'
import {useDispatch} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {Box, Button, Container, Switch} from '@mui/material'
import {retrieveLaunchParams} from '@telegram-apps/sdk'
import {AppDispatch} from '../../store/store'
import {setRedirectPath} from '../../store/appSlice'
import {getBooleanFromStorage, setBooleanToStorage} from '../../store/storage'
import {CopyToClipboardBlock} from '../elements'

const Settings = () => {
    const {t} = useTranslation()
    const launchParams = retrieveLaunchParams()
    const [soundsOn, setSoundsOn] = useState(getBooleanFromStorage('soundsOn'))

    const dispatch = useDispatch<AppDispatch>()

    const okHandler = () => {
        dispatch(setRedirectPath('/'))
    }
    const saveHandler = () => {
        setBooleanToStorage('soundsOn', soundsOn)
        dispatch(setRedirectPath('/'))
    }

    return <Container>
        <Box textAlign={'center'} sx={{mb: 1, mt: 2}}>
            Sounds: <Switch checked={soundsOn} onChange={() => setSoundsOn(!soundsOn)}/>
        </Box>
        <Box textAlign={'center'}>
            <Button
                variant={'contained'}
                sx={{m: 1}}
                onClick={saveHandler}
            >{t('button.save')}</Button>
        </Box>
        <Box textAlign={'center'} sx={{mt: 4}}>
            {t('text.inviteLink')}:<br/>https://t.me/bet_mini_bot?start={launchParams.initData?.user?.id || ''}
            {' '}
            <CopyToClipboardBlock text={`https://t.me/bet_mini_bot?start=${launchParams.initData?.user?.id || ''}`}/>
        </Box>
        <Box textAlign={'center'}>
            <Button
                variant={'contained'}
                sx={{m: 1}}
                onClick={okHandler}
            >{t('button.ok')}</Button>
        </Box>
    </Container>
}

export default Settings
