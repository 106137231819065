import {configureStore} from '@reduxjs/toolkit'
import appReducer from './appSlice'
import authReducer from './authSlice'
import currenciesReducer from './currenciesSlice'
import ensReducer from './ensSlice'
import profileReducer from './profileSlice'

const store = configureStore({
    reducer: {
        app: appReducer,
        auth: authReducer,
        currencies: currenciesReducer,
        ens: ensReducer,
        profile: profileReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [
                    'app/addChainQueue',
                    'app/addRequestToQueue',
                    'app/addToRequestQueue',
                    'app/setSigner',
                ],
                ignoredPaths: [
                    'app.chainQueue',
                    'app.requestQueue',
                    'app.signer',
                    'app.web3',
                ],
            },
        }),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store
