type StorageKeys = 'connectedWallet' | 'referrer' | 'walletAddress' | 'language' | 'soundsOn'
type StorageWalletKeys = 'jwt'
type StorageNetworkKeys = ''

export const getBooleanFromStorage = (key: StorageKeys, def: boolean = true) => {
    if (def) {
        return getFromStorage(key) !== 'false'
    } else {
        return getFromStorage(key) !== 'true'
    }
}

export const getFromStorage = (key: StorageKeys): string | null => {
    const value = localStorage.getItem(key)
    return value === '' ? null : value
}

export const getFromNetworkStorage = (key: StorageNetworkKeys, network: string | null): string | null => {
    if (!network) {
        return null
    }

    const wallet = getFromStorage('walletAddress')
    if (!wallet) {
        return null
    }

    const value = localStorage.getItem(`${wallet}:${network}:${key}`)
    return value === '' ? null : value
}

export const getFromWalletStorage = (key: StorageWalletKeys): string | null => {
    const wallet = getFromStorage('walletAddress')
    if (!wallet) {
        return null
    }

    const value = localStorage.getItem(`${wallet}:${key}`)
    return value === '' ? null : value
}

export const setBooleanToStorage = (key: StorageKeys, value: boolean) => {
    setToStorage(key, value ? 'true' : 'false')
}

export const setToStorage = (key: StorageKeys, value: string | null): void => {
    localStorage.setItem(key, value || '')
}

export const setToNetworkStorage = (key: StorageNetworkKeys, value: string | number | null, network: string | null): void => {
    if (!network) {
        return
    }

    const wallet = getFromStorage('walletAddress')
    if (!wallet) {
        return
    }

    localStorage.setItem(`${wallet}:${network}:${key}`, value?.toString() || '')
}

export const setToWalletStorage = (key: StorageWalletKeys, value: string | number | null): void => {
    const wallet = getFromStorage('walletAddress')
    if (!wallet) {
        return
    }

    localStorage.setItem(`${wallet}:${key}`, value?.toString() || '')
}
