import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {NavLink} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {AppBar, Button, Toolbar, Typography} from '@mui/material'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import SettingsIcon from '@mui/icons-material/Settings'
import {getProfile, requestMoreBalance} from '../../store/profileSlice'
import {AppDispatch} from '../../store/store'

const Header = () => {
    const {t} = useTranslation()
    const profile = useSelector(getProfile)

    const dispatch = useDispatch<AppDispatch>()

    const getMoreHandler = () => {
        dispatch(requestMoreBalance())
    }

    return <AppBar position="static" sx={{mb: 1}}>
        <Toolbar>
            <AttachMoneyIcon/>
            <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
                {profile ?
                    <>
                        {profile.balance}
                        {profile.balance === '0' ?
                            <Button onClick={getMoreHandler}>{t('button.getMore')}</Button> : null}
                    </>
                    :
                    '...'
                }
            </Typography>
            <NavLink to={'/settings'}><SettingsIcon color={'primary'}/></NavLink>
        </Toolbar>
    </AppBar>
}

export default Header
