export interface ChainType {
    blockExplorer: string
    blockExplorerNftLink: (contract: string, tokenId: string) => string
    label: string
    rpcUrl: string
    testnet: boolean
    tld: string
    token: string
    tokenPrecision: number
}

const INFURA_KEY = process.env.REACT_APP_INFURA_KEY?.trim() || ''
export const CHAINS: { [key: string]: ChainType } = {
    '0x1': {
        token: 'ETH',
        tokenPrecision: 18,
        label: 'Ethereum',
        testnet: false,
        rpcUrl: `https://mainnet.infura.io/v3/${INFURA_KEY}`,
        blockExplorer: 'https://etherscan.io',
        blockExplorerNftLink: (contract, tokenId) => (`https://etherscan.io/nft/${contract}/${tokenId}`),
        tld: '',
    },
    '0x38': {
        token: 'BNB',
        tokenPrecision: 18,
        label: 'BSC/BNB chain',
        testnet: false,
        rpcUrl: 'https://bsc-dataseed.binance.org/',
        blockExplorer: 'https://bscscan.com',
        blockExplorerNftLink: (contract, tokenId) => (`https://bscscan.com/nft/${contract}/${tokenId}`),
        tld: 'bnb',
    },
    '0x89': {
        token: 'MATIC',
        tokenPrecision: 18,
        label: 'Polygon',
        testnet: false,
        rpcUrl: 'https://polygon-rpc.com/',
        blockExplorer: 'https://polygonscan.com',
        blockExplorerNftLink: (contract, tokenId) => (`https://polygonscan.com/nft/${contract}/${tokenId}`),
        tld: '',
    },
    '0xa4b1': {
        token: 'ETH',
        tokenPrecision: 18,
        label: 'Arbitrum',
        testnet: false,
        rpcUrl: 'https://arb1.arbitrum.io/rpc',
        blockExplorer: 'https://arbiscan.io',
        blockExplorerNftLink: (contract, tokenId) => (`https://arbiscan.io/token/${contract}?a=${tokenId}`),
        tld: 'arb',
    },
    '0x13e31': {
        token: 'ETH',
        tokenPrecision: 18,
        label: 'Blast',
        testnet: false,
        rpcUrl: 'https://rpc.blast.io',
        blockExplorer: 'https://blastscan.io',
        blockExplorerNftLink: (contract, tokenId) => (`https://blastscan.io/token/${contract}?a=${tokenId}`),
        tld: '',
    },
    '0xaa36a7': {
        token: 'ETH',
        tokenPrecision: 18,
        label: 'Sepolia',
        testnet: true,
        rpcUrl: `https://sepolia.infura.io/v3/${INFURA_KEY}`,
        blockExplorer: 'https://sepolia.etherscan.io',
        blockExplorerNftLink: (contract, tokenId) => (`https://sepolia.etherscan.io/token/${contract}?a=${tokenId}`),
        tld: '',
    },
}

export const COPY_HINT_TIME = 2000
export const NULL_ADDRESS = '0x0000000000000000000000000000000000000000'

export const API_URL = process.env.REACT_APP_API_URL?.trim() || '/api/'
export const APP_URL = process.env.REACT_APP_URL?.trim() || ''
export const WALLETCONNECT_ID = process.env.REACT_APP_WALLETCONNECT_ID?.trim() || ''
export const LOCALIZATION_DEBUG = process.env.REACT_APP_LOCALIZATION_DEBUG?.trim().toLowerCase() === 'true' || false
