import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import axios from 'axios'
import i18next from 'i18next'
import {API_URL} from '../utils/constants'
import {RootState} from './store'
import {IBet, IProfile, SliceResponse, toBetType, toProfileType} from './types'
import {checkResponse} from './appSlice'

interface CurrenciesState {
    activeBets: IBet[] | null
    profile: IProfile | undefined | null
}

const initialState: CurrenciesState = {
    activeBets: null,
    profile: null,
}

export const makeBet = createAsyncThunk(
    'profile/makeBet',
    async (params: {amount: number, period: number, up: boolean}, {getState, dispatch}): Promise<void> => {
        const {amount, period, up} = params
        const state = getState() as RootState
        const {initDataRow} = state.auth

        let response: SliceResponse = {}
        if (!initDataRow) {
            response.error = {text: i18next.t('error.tgAuthError')}
        } else {
            try {
                const config: any = {headers: {'authorization': `tma ${initDataRow}`}}
                const result = await axios.post(`${API_URL}bet/${amount}/${period}/${up}`, {}, config)
                response.status = result.status
                response.data = null
            } catch (e: any) {
                response.defaultData = null
                if (e.response) {
                    response.status = e.response.status
                    response.error = {text: e.response.data.error}
                } else {
                    response.error = {text: e.message}
                }
            }
        }
        response.setData = (value) => {
            dispatch(setActiveBets(value))
        }
        response.afterCheckCallback = () => {
            dispatch(requestProfile())
        }
        dispatch(checkResponse(response))
    }
)

export const requestActiveBets = createAsyncThunk(
    'profile/requestActiveBets',
    async (_, {getState, dispatch}): Promise<void> => {
        const state = getState() as RootState
        const {initDataRow} = state.auth

        let response: SliceResponse = {}
        if (!initDataRow) {
            response.error = {text: i18next.t('error.tgAuthError')}
        } else {
            try {
                const config: any = {headers: {'authorization': `tma ${initDataRow}`}}
                const result = await axios.get(`${API_URL}bets/active`, config)
                response.status = result.status
                let bets: IBet[] = []
                for (let item of result.data.bets || []) {
                    bets.push(toBetType(item))
                }
                response.data = bets
            } catch (e: any) {
                response.defaultData = []
                if (e.response) {
                    response.status = e.response.status
                    response.error = {text: e.response.data.error}
                } else {
                    response.error = {text: e.message}
                }
            }
        }
        response.setData = (value) => {
            dispatch(setActiveBets(value))
        }
        dispatch(checkResponse(response))
    }
)

export const requestMoreBalance = createAsyncThunk(
    'profile/requestMoreBalance',
    async (_, {getState, dispatch}): Promise<void> => {
        const state = getState() as RootState
        const {initDataRow} = state.auth

        let response: SliceResponse = {}
        if (!initDataRow) {
            response.error = {text: i18next.t('error.tgAuthError')}
        } else {
            try {
                const config: any = {headers: {'authorization': `tma ${initDataRow}`}}
                const result = await axios.put(`${API_URL}profile/requestbalance`, {}, config)
                response.status = result.status
                response.data = toProfileType(result.data)
            } catch (e: any) {
                response.defaultData = undefined
                if (e.response) {
                    response.status = e.response.status
                    response.error = {text: e.response.data.error}
                } else {
                    response.error = {text: e.message}
                }
            }
        }
        response.setData = (value) => {
            dispatch(setProfile(value))
        }
        dispatch(checkResponse(response))
    }
)

export const requestProfile = createAsyncThunk(
    'profile/requestProfile',
    async (_, {getState, dispatch}): Promise<void> => {
        const state = getState() as RootState
        const {initDataRow} = state.auth

        let response: SliceResponse = {}
        if (!initDataRow) {
            response.error = {text: i18next.t('error.tgAuthError')}
        } else {
            try {
                const config: any = {headers: {'authorization': `tma ${initDataRow}`}}
                const result = await axios.get(`${API_URL}profile`, config)
                response.status = result.status
                response.data = toProfileType(result.data)
            } catch (e: any) {
                response.defaultData = undefined
                if (e.response) {
                    response.status = e.response.status
                    response.error = {text: e.response.data.error}
                } else {
                    response.error = {text: e.message}
                }
            }
        }
        response.setData = (value) => {
            dispatch(setProfile(value))
        }
        dispatch(checkResponse(response))
    }
)

export const profileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        setActiveBets: (state, action: PayloadAction<IBet[] | null>) => {
            state.activeBets = action.payload
        },
        setProfile: (state, action: PayloadAction<IProfile | undefined | null>) => {
            state.profile = action.payload
        },
    },
})

export const getActiveBets = (state: RootState): IBet[] | null => state.profile.activeBets
export const getActiveBetsObject = (state: RootState): {[period: number]: IBet} => {
    let object: {[period: number]: IBet} = {}
    for (let item of state.profile.activeBets || []) {
        object[item.betPeriod] = item
    }
    return object
}
export const getProfile = (state: RootState): IProfile | undefined | null => state.profile.profile

export const {
    setActiveBets,
    setProfile,
} = profileSlice.actions

export default profileSlice.reducer
