import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Badge, Box, Button, ButtonGroup, Container, Paper, Typography} from '@mui/material'
import {TrendingDown, TrendingUp} from '@mui/icons-material'
import {useTranslation} from 'react-i18next'
import useSound from 'use-sound'
import BitcoinImage from '../../static/img/bitcoin.png'
import {AppDispatch} from '../../store/store'
import {getCurrency, requestCurrency} from '../../store/currenciesSlice'
import {getActiveBets, getActiveBetsObject, makeBet, requestActiveBets, requestProfile} from '../../store/profileSlice'
import notifyMp3 from '../../static/sounds/notify.mp3'
import {getBooleanFromStorage} from '../../store/storage'

interface IButton {
    title: string
    value: number
}

const betButtons: IButton[] = [
    {title: '1$', value: 1},
    {title: '5$', value: 5},
    {title: '10$', value: 10},
]
const timeButtons: IButton[] = [
    {title: '1 min', value: 60},
    {title: '5 min', value: 300},
    {title: '1 hour', value: 3600},
    {title: '1 day', value: 86400},
]
const ticker = 'BTC'

const BetApp = () => {
    const {t} = useTranslation()
    const [activeBetButton, setActiveBetButton] = useState(0)
    const [activeTimeButton, setActiveTimeButton] = useState(0)
    const [updater, setUpdater] = useState(true)
    const activeBets = useSelector(getActiveBets)
    const activeBetsObject = useSelector(getActiveBetsObject)
    const currency = useSelector(getCurrency(ticker))
    let leftTimeString = ''
    let priceDelta = 0
    let activeBet = activeBetsObject[timeButtons[activeTimeButton].value]
    if (activeBet) {
        const seconds = Math.floor((activeBet.betUntil - Date.now()) / 1000)
        const minutes = Math.floor(seconds / 60)
        const hours = Math.floor(seconds / 3600)
        if (hours > 0) {
            leftTimeString = `${hours} ${t('word.hours')}`
        } else if (minutes > 0) {
            leftTimeString = `${minutes} ${t('word.minutes')}`
        } else {
            leftTimeString = `${seconds > 0 ? seconds : 0} ${t('word.seconds')}`
        }
        if (currency) {
            priceDelta = currency.price - activeBet.price
        }
    }
    const soundsOn = getBooleanFromStorage('soundsOn')

    const dispatch = useDispatch<AppDispatch>()
    const [playNotify] = useSound(notifyMp3)

    useEffect(() => {
        if (!activeBets) {
            dispatch(requestActiveBets())
        }
    }, [activeBets])
    useEffect(() => {
        setTimeout(() => {
            setUpdater(!updater)
        }, 1000)
        dispatch(requestCurrency(ticker))
        for (let item of activeBets || []) {
            if (item.betUntil < Date.now()) {
                dispatch(requestActiveBets())
                dispatch(requestProfile())
                if (soundsOn) {
                    playNotify()
                }
                break
            }
        }
    }, [updater])

    const makeBetHandler = (up: boolean) => {
        dispatch(makeBet({
            amount: betButtons[activeBetButton].value,
            period: timeButtons[activeTimeButton].value,
            up,
        }))
    }

    return <Container>
        <Box textAlign={'center'} sx={{mb: 1}}>
            <ButtonGroup variant="contained">
                {timeButtons.map((item, index) => {
                    return <Badge key={index} variant={'dot'}
                                  color={activeBetsObject[item.value] ? 'success' : 'error'}>
                        <Button
                            disabled={activeTimeButton === index}
                            onClick={() => {
                                setActiveTimeButton(index)
                            }}
                        >{item.title}</Button>
                    </Badge>
                })}
            </ButtonGroup>
        </Box>
        <Box textAlign={'center'} sx={{mb: 1}}>
            <ButtonGroup variant="contained">
                {betButtons.map((item, index) => {
                    return <Button
                        key={index}
                        disabled={activeBetButton === index}
                        onClick={() => {
                            setActiveBetButton(index)
                        }}
                    >{item.title}</Button>
                })}
            </ButtonGroup>
        </Box>
        <Paper sx={{
            backgroundImage: `url(${BitcoinImage})`,
            backgroundPosition: 'center center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            aspectRatio: '1/1',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            textAlign: 'center',
        }}>
            <Typography variant={'h2'} sx={{width: 1}}>
                {currency ? currency.price : `${t('status.loading')}...`}
            </Typography>
            {activeBet ?
                <Typography variant={'h4'} sx={{width: 1, color: priceDelta > 0 ? 'green' : 'red'}}>
                    {priceDelta.toFixed(2)}
                </Typography>
                :
                null
            }
        </Paper>
        {activeBet && currency ?
            <Box textAlign={'center'}>
                <Typography variant={'subtitle1'} sx={{width: 1}}>
                    {t('status.activeBet', {
                        name: activeBet.betUp ? t('button.up') : t('button.down'),
                        value: activeBet.betAmount,
                    })} ({leftTimeString})
                </Typography>
                <Typography variant={'subtitle1'} sx={{width: 1}}>
                    {(activeBet.betUp && activeBet.price < currency.price) || (!activeBet.betUp && activeBet.price > currency.price) ?
                        t('status.willWon', {value: activeBet.betAmount * 2})
                        :
                        t('status.willLose')
                    }
                </Typography>
            </Box>
            :
            <Box textAlign={'center'}>
                <Button
                    variant={'contained'}
                    sx={{m: 1}}
                    onClick={() => {
                        makeBetHandler(false)
                    }}
                >
                    {t('button.down')}<TrendingDown/>
                </Button>
                <Button
                    variant={'contained'}
                    sx={{m: 1}}
                    onClick={() => {
                        makeBetHandler(true)
                    }}
                >
                    {t('button.up')}<TrendingUp/>
                </Button>
            </Box>
        }
    </Container>
}

export default BetApp
