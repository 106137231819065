import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import {getRedirectPath, setRedirectPath} from '../../store/appSlice'

const Redirect = () => {
    const redirectPath = useSelector(getRedirectPath)

    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        if (redirectPath) {
            navigate(redirectPath)
            dispatch(setRedirectPath(null))
        }
    }, [redirectPath])

    return <></>
}

export default Redirect
