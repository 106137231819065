import {AsyncThunkAction, createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {Signer} from 'ethers'
import i18next from 'i18next'
import {RootState} from './store'
import Web3 from 'web3'
import {CHAINS} from '../utils/constants'
import {SliceResponse, IWalletQueue} from './types'
import {getFromStorage, getFromWalletStorage, setToStorage} from './storage'
import {requestAuth, setJwt, setUser} from './authSlice'
import {setNames} from './ensSlice'
import {sleep} from '../utils/functions'

interface AppState {
    currentNetwork: string | null
    initialized: boolean
    initializeError: string
    redirectPath: string | null
    requestQueue: AsyncThunkAction<any, any, any>[]
    signer: Signer | null
    walletAddress: string | null
    walletQueue: IWalletQueue[]
    web3: Web3 | null
}

const initialState: AppState = {
    currentNetwork: null,
    initialized: false,
    initializeError: '',
    redirectPath: null,
    requestQueue: [],
    signer: null,
    walletAddress: null,
    walletQueue: [],
    web3: null,
}

export const checkResponse = createAsyncThunk(
    'app/checkResponse',
    async (response: SliceResponse, {dispatch}): Promise<void> => {
        response.beforeCheckCallback?.()
        switch (response.status) {
            case 200:
                response.successCallback?.()
                break
            case 401:
                //todo: show auth error
                break
            default:
                response.error = {
                    title: i18next.t('error.error'),
                    text: response.error?.text || i18next.t('error.someError'),
                }
        }
        let data = response.data
        if (response.error) {
            if (response.status !== 401) {
//                dispatch(setModalError({...response.error, buttons: ['close']}))
            }
            data = response.defaultData || null
        }
        response.setData?.(data)
        response.afterCheckCallback?.()
    }
)

export const checkWallet = createAsyncThunk(
    'app/checkWallet',
    async (params: IWalletQueue, {dispatch, getState}): Promise<void> => {
        const {request, network} = params
        const state = getState() as RootState
        const {currentNetwork, walletAddress} = state.app

        if (currentNetwork && walletAddress && (!network || network === currentNetwork)) {
            dispatch(request)
        } else {
            dispatch(addToWalletQueue(params))
        }
    }
)

export const initialize = createAsyncThunk(
    'app/initialize',
    async (_, {dispatch, getState}): Promise<boolean> => {
        const connectedWallet = getFromStorage('connectedWallet')
        if (!connectedWallet) {
            setToStorage('walletAddress', null)
        }
        dispatch(setJwt(getFromWalletStorage('jwt')))
        const time = Date.now()
        while (time + 10000 > Date.now()) {
            const state = getState() as RootState
            const {walletAddress} = state.app
            const {initDataRow} = state.auth
            if ((!connectedWallet || walletAddress) && initDataRow) {
                return true
            }
            await sleep(1000)
        }
        if (connectedWallet) {
            setToStorage('connectedWallet', null)
            return true
        }
        return false
    }
)

export const initializeNetwork = createAsyncThunk(
    'app/initializeNetwork',
    async (_, {dispatch, getState}): Promise<void> => {
/*
        const state = getState() as RootState
        const {currentNetwork} = state.app

        dispatch(setSelectedMintCollection(getFromNetworkStorage('selectedMintCollection', currentNetwork)))
        dispatch(setSelectedSbtCollection(getFromNetworkStorage('selectedSbtCollection', currentNetwork)))
*/
    }
)

export const networkChanged = createAsyncThunk(
    'app/networkChanged',
    async (_, {dispatch, getState}): Promise<void> => {
        const state = getState() as RootState
        const {currentNetwork, walletAddress} = state.app

        dispatch(setNames({}))
        if (walletAddress) {
/*
            dispatch(setMintCollections(null))
            dispatch(setSelectedMintCollection(getFromNetworkStorage('selectedMintCollection', currentNetwork)))
            dispatch(setSelectedSbtCollection(getFromNetworkStorage('selectedSbtCollection', currentNetwork)))
*/
        }
    }
)

export const sendRequestWithAuth = createAsyncThunk(
    'app/sendRequestWithAuth',
    async (request: AsyncThunkAction<any, any, any>, {dispatch, getState}): Promise<void> => {
        const state = getState() as RootState
        const {user} = state.auth

        if (!user || !user.auth) {
            dispatch(addToRequestQueue(request))
        } else {
            dispatch(request)
        }
    }
)

export const walletChanged = createAsyncThunk(
    'app/walletChanged',
    async (_, {dispatch, getState}): Promise<void> => {
        const state = getState() as RootState
        const {currentNetwork, walletAddress} = state.app

        if (!walletAddress) {
            return
        }

        dispatch(setUser(null))
        const jwt = getFromWalletStorage('jwt')
        dispatch(setJwt(jwt))
        dispatch(requestAuth({account: walletAddress, jwt}))
    }
)

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        addToRequestQueue: (state, action: PayloadAction<AsyncThunkAction<any, any, any>>) => {
            state.requestQueue.push(action.payload)
        },
        addToWalletQueue: (state, action: PayloadAction<IWalletQueue>) => {
            state.walletQueue.push(action.payload)
        },
        resetRequestQueue: (state) => {
            state.requestQueue = []
        },
        resetWalletQueue: (state) => {
            state.walletQueue = []
        },
        setCurrentNetwork: (state, action: PayloadAction<string | null>) => {
            if (action.payload) {
                if (!!CHAINS[action.payload]) {
                    state.web3 = new Web3(CHAINS[action.payload].rpcUrl)
                    state.currentNetwork = action.payload
//                    state.modalError = null
                    return
                }
            }
            state.web3 = null
            state.currentNetwork = null
        },
        setRedirectPath: (state, action: PayloadAction<string | null>) => {
            state.redirectPath = action.payload
        },
        setSigner: (state, action: PayloadAction<Signer | null>) => {
            state.signer = action.payload
        },
        setWalletAddress: (state, action: PayloadAction<string | null>) => {
            state.walletAddress = action.payload
            setToStorage('walletAddress', action.payload)
        },
        setWalletQueue: (state, action: PayloadAction<IWalletQueue[]>) => {
            state.walletQueue = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(initialize.fulfilled, (state, action: PayloadAction<boolean>) => {
            if (action.payload) {
                state.initialized = action.payload
            } else {
                state.initializeError = i18next.t('error.initializeError')
            }
        })
    },
})

export const getCurrentNetwork = (state: RootState): string | null => state.app.currentNetwork
export const getInitialized = (state: RootState): boolean => state.app.initialized
export const getInitializeError = (state: RootState): string => state.app.initializeError
export const getRedirectPath = (state: RootState): string | null => state.app.redirectPath
export const getRequestQueue = (state: RootState): AsyncThunkAction<any, any, any>[] => state.app.requestQueue
export const getSigner = (state: RootState): Signer | null => state.app.signer
export const getWalletAddress = (state: RootState): string | null => state.app.walletAddress
export const getWalletQueue = (state: RootState): IWalletQueue[] => state.app.walletQueue
export const getWeb3 = (state: RootState): Web3 | null => state.app.web3

export const {
    addToRequestQueue,
    addToWalletQueue,
    resetRequestQueue,
    resetWalletQueue,
    setCurrentNetwork,
    setRedirectPath,
    setSigner,
    setWalletAddress,
    setWalletQueue,
} = appSlice.actions

export default appSlice.reducer
