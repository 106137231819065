import {AsyncThunkAction, PayloadAction} from '@reduxjs/toolkit'

export interface IAuthUser {
    address: string
    msg: string
    auth: boolean
    iat: number
    exp: number
}

export interface IBet {
    betAmount: number
    betPeriod: number
    betUntil: number
    betUp: boolean
    price: number
    status: boolean
    telegramId: bigint
}

export interface ICurrency {
    price: number
    ticker: string
}

export interface ICurrencies {
    [ticker: string]: ICurrency
}

export interface IProfile {
    balance: string
    telegramId: bigint
}
export interface IWalletQueue {
    request: AsyncThunkAction<any, any, any> | PayloadAction<any>
    network?: string
}

export interface ModalErrorType {
    title?: string
    text?: string[] | string
    buttons?: ('close' | 'chooseNetwork')[]
    links?: string[]
}

export interface SliceResponse {
    afterCheckCallback?: () => void
    beforeCheckCallback?: () => void
    error?: ModalErrorType
    data?: any
    defaultData?: any
    setData?: (data: any) => void
    status?: number | null
    successCallback?: () => void
}

export const toBetType = (data: any): IBet => {
    return {
        betAmount: Number(data.betAmount),
        betPeriod: Number(data.betPeriod),
        betUntil: Number(data.betUntil),
        betUp: data.betUp,
        price: Number(data.price),
        status: data.status,
        telegramId: BigInt(data.telegramId),
    }
}
export const toProfileType = (data: any): IProfile => {
    return {
        balance: data.balance,
        telegramId: BigInt(data.telegramId),
    }
}
