import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import axios from 'axios'
import i18next from 'i18next'
import {API_URL} from '../utils/constants'
import {RootState} from './store'
import {IAuthUser, SliceResponse} from './types'
import {checkResponse, setCurrentNetwork, setWalletAddress} from './appSlice'
import {setToStorage, setToWalletStorage} from './storage'

interface AuthState {
    initDataRow: string | undefined
    jwt: string | null
    signingNonce: boolean
    user: IAuthUser | null
}

const initialState: AuthState = {
    initDataRow: undefined,
    jwt: null,
    signingNonce: false,
    user: null,
}

export const resetAuth = createAsyncThunk(
    'auth/resetAuth',
    async (_, {dispatch}): Promise<void> => {
        setToWalletStorage('jwt', null)
        setToStorage('connectedWallet', null)
        dispatch(setWalletAddress(null))
        dispatch(setCurrentNetwork(null))
        dispatch(setUser(null))
        dispatch(setJwt(null))
    }
)
export const requestAuth = createAsyncThunk(
    'auth/requestAuth',
    async ({account, jwt}: { account: string, jwt: string | null }, {dispatch}): Promise<void> => {
        let response: SliceResponse = {}
        try {
            let config: any = {}
            if (jwt) {
                config['headers'] = {'authorization': `Bearer ${jwt}`}
            }
            const result = await axios.get(`${API_URL}auth/${account}`, config)
            response.status = result.status
            response.data = result.data.user
            response.setData = (value) => {
                dispatch(setUser(value))
                if (!value.auth) {
                    dispatch(setJwt(null))
                }
            }
        } catch (e: any) {
            response.defaultData = null
            response.setData = (value) => {
                dispatch(setUser(value))
                dispatch(setJwt(null))
            }
            if (e.response) {
                response.status = e.response.status
                response.error = {text: e.response.data.error}
            } else {
                response.error = {text: e.message}
            }
        }
        dispatch(checkResponse(response))
    }
)
export const signUserNonce = createAsyncThunk(
    'auth/signUserNonce',
    async (_, {dispatch, getState}): Promise<void> => {
        const state = getState() as RootState
        const {signer} = state.app
        const {user} = state.auth

        let response: SliceResponse = {}
        response.beforeCheckCallback = () => {
            dispatch(setSigningNonce(true))
        }
        if (!signer || !user) {
            response.error = {text: i18next.t('error.userNotFound')}
        } else {
            try {
                const signedMsg = await signer.signMessage(user.msg)
                const result = await axios.post(`${API_URL}auth/${user.address}`, {
                    msg: signedMsg,
                })
                response.status = result.status
                response.data = result.data.jwt
                response.setData = (value) => {
                    dispatch(setJwt(value))
                }
            } catch (e: any) {
                response.defaultData = null
                response.setData = (value) => {
                    dispatch(setJwt(value))
                }
                if (e.response) {
                    response.status = e.response.status
                    response.error = {text: e.response.data.error}
                } else {
                    response.error = {text: e.message}
                }
            }
        }
        response.afterCheckCallback = () => {
            dispatch(setSigningNonce(false))
        }
        dispatch(checkResponse(response))
    }
)

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        reset: (state) => {
            setToWalletStorage('jwt', null)
            state.jwt = null
            state.user = null
        },
        setInitDataRaw: (state, action: PayloadAction<string | undefined>) => {
            state.initDataRow = action.payload
        },
        setJwt: (state, action: PayloadAction<string | null>) => {
            state.jwt = action.payload
            setToWalletStorage('jwt', action.payload)
        },
        setSigningNonce: (state, action: PayloadAction<boolean>) => {
            state.signingNonce = action.payload
        },
        setUser: (state, action: PayloadAction<IAuthUser | null>) => {
            state.user = action.payload
        },
    },
})

export const getInitDataRow = (state: RootState): string | undefined => state.auth.initDataRow
export const getJwt = (state: RootState): string | null => state.auth.jwt
export const getSigningNonce = (state: RootState): boolean => state.auth.signingNonce
export const getUser = (state: RootState): IAuthUser | null => state.auth.user

export const {
    reset,
    setInitDataRaw,
    setJwt,
    setSigningNonce,
    setUser
} = authSlice.actions

export default authSlice.reducer
