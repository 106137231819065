import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useConnectWallet} from '@web3-onboard/react'
import {useTranslation} from 'react-i18next'
import {mockTelegramEnv, parseInitData} from '@telegram-apps/sdk-react'
import {getInitializeError, initialize} from '../../store/appSlice'
import {getFromStorage} from '../../store/storage'
import {AppDispatch} from '../../store/store'

const Initialize = () => {
    const {t} = useTranslation()
    const error = useSelector(getInitializeError)
    const hash = window.location.hash
    if (hash === '') {
        const initDataRawTmp = new URLSearchParams([
            ['user', JSON.stringify({
                id: 99281932,
                first_name: 'Andrew',
                last_name: 'Rogue',
                username: 'rogue',
                language_code: 'en',
                is_premium: true,
                allows_write_to_pm: true,
            })],
            ['hash', '89d6079ad6762351f38c6dbbc41bb53048019256a9443988af7a48bcad16ba31'],
            ['auth_date', '1716922846'],
            ['start_param', 'debug'],
            ['chat_type', 'sender'],
            ['chat_instance', '8428209589180549439'],
        ]).toString()

        mockTelegramEnv({
            themeParams: {
                accentTextColor: '#6ab2f2',
                bgColor: '#17212b',
                buttonColor: '#5288c1',
                buttonTextColor: '#ffffff',
                destructiveTextColor: '#ec3942',
                headerBgColor: '#17212b',
                hintColor: '#708499',
                linkColor: '#6ab3f3',
                secondaryBgColor: '#232e3c',
                sectionBgColor: '#17212b',
                sectionHeaderTextColor: '#6ab3f3',
                subtitleTextColor: '#708499',
                textColor: '#f5f5f5',
            },
            initData: parseInitData(initDataRawTmp),
            initDataRaw: initDataRawTmp,
            version: '7.2',
            platform: 'tdesktop',
        });
    }
    const dispatch = useDispatch<AppDispatch>()
    const [_, connect] = useConnectWallet()

    useEffect(() => {
        dispatch(initialize())
        const connectedWallet = getFromStorage('connectedWallet')
        if (connectedWallet) {
            connect({autoSelect: {label: connectedWallet, disableModals: true}})
        }
    }, [])

    return <>{error === '' ? `${t('status.initializing')}...` : error}</>
}

export default Initialize
