import React, {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {Outlet} from 'react-router-dom'
import {Container, CssBaseline} from '@mui/material'
import {Footer, Header} from '.'
import {Redirect, Referral} from '../system'
import {AppDispatch} from '../../store/store'
import {requestProfile} from '../../store/profileSlice'

const LayoutDefault = () => {
    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        dispatch(requestProfile())
    }, [])

    return <>
        <CssBaseline/>
        <Referral/>
        <Redirect/>
        <Container maxWidth="sm">
            <Header/>
            <Outlet/>
            <Footer/>
        </Container>
    </>
}

export default LayoutDefault
