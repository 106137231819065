import i18next from 'i18next'
import ICU from 'i18next-icu'
import {initReactI18next} from 'react-i18next'
import enTranslation from './en/translation.json'
import ruTranslation from './ru/translation.json'
import {LOCALIZATION_DEBUG} from '../utils/constants'
import {getFromStorage, setToStorage} from '../store/storage'

const availableLang = ['en', 'ru']
let lng = getFromStorage('language')
if (!lng || availableLang.indexOf(lng) < 0) {
    lng = 'en'
}
i18next
    .use(ICU)
    .use(initReactI18next)
    .init({
        lng,
        debug: LOCALIZATION_DEBUG,
        fallbackLng: 'en',
        interpolation: {escapeValue: false},
        resources: {
            en: {translation: enTranslation},
            ru: {translation: ruTranslation},
        },
    })

export const changeLang = () => {
    const lang = i18next.language === 'en' ? 'ru' : 'en'
    setToStorage('language', lang)
    i18next.changeLanguage(lang)
}
