import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import axios from 'axios'
import i18next from 'i18next'
import {API_URL} from '../utils/constants'
import {RootState} from './store'
import {ICurrencies, ICurrency, SliceResponse} from './types'
import {checkResponse} from './appSlice'

interface CurrenciesState {
    currencies: ICurrencies,
}

const initialState: CurrenciesState = {
    currencies: {},
}

export const requestCurrency = createAsyncThunk(
    'currencies/requestCurrency',
    async (ticker: string, {getState, dispatch}): Promise<void> => {
        const state = getState() as RootState
        const {initDataRow} = state.auth

        let response: SliceResponse = {}
        if (!initDataRow) {
            response.error = {text: i18next.t('error.tgAuthError')}
        } else if (ticker.trim() === '') {
            response.error = {text: i18next.t('error.wrongParams')}
        } else {
            try {
                const config: any = {headers: {'authorization': `tma ${initDataRow}`}}
                const result = await axios.get(`${API_URL}currencies/${ticker}/price`, config)
                let currency: ICurrency = {
                    price: result.data.price,
                    ticker,
                }
                response.status = result.status
                response.data = currency
            } catch (e: any) {
                response.defaultData = {price: 'not loaded', ticker}
                if (e.response) {
                    response.status = e.response.status
                    response.error = {text: e.response.data.error}
                } else {
                    response.error = {text: e.message}
                }
            }
        }
        response.setData = (value) => {
            dispatch(setCurrency(value))
        }
        dispatch(checkResponse(response))
    }
)

export const currenciesSlice = createSlice({
    name: 'currencies',
    initialState,
    reducers: {
        setCurrency: (state, action: PayloadAction<ICurrency>) => {
            state.currencies[action.payload.ticker] = action.payload
        },
        setCurrencies: (state, action: PayloadAction<ICurrencies | null>) => {
            if (!action.payload) {
                state.currencies = {}
            } else {
                state.currencies = action.payload
            }
        },
    },
})

export const getCurrency = (ticker: string) => (state: RootState): ICurrency | undefined => state.currencies.currencies[ticker]
export const getCurrencies = (state: RootState): ICurrencies => state.currencies.currencies

export const {
    setCurrency,
    setCurrencies,
} = currenciesSlice.actions

export default currenciesSlice.reducer
