import React from 'react'
import {useSelector} from 'react-redux'
import {Initialize} from './layouts'
import {getInitialized} from '../store/appSlice'
import App from '../App'
import {Authorization} from './auth'

const Root = () => {
    const initialized = useSelector(getInitialized)

    return <>
        <Authorization/>
        {initialized ? <App/> : <Initialize/>}
    </>
}

export default Root
