import React from 'react'
import ReactDOM from 'react-dom/client'
import {Provider} from 'react-redux'
import {Web3OnboardProvider, init} from '@web3-onboard/react'
import injectedModule from '@web3-onboard/injected-wallets'
import walletConnectModule from '@web3-onboard/walletconnect'
import {AccountCenterOptions} from '@web3-onboard/core/dist/types'
import {createTheme, ThemeProvider} from '@mui/material'
import {SDKProvider} from '@telegram-apps/sdk-react'
import reportWebVitals from './reportWebVitals'
import store from './store/store'
import {getChainsForOnboard} from './utils/functions'
import {APP_URL, WALLETCONNECT_ID} from './utils/constants'
import Root from './components/Root'
import './i18n/config'

import app_icon from './static/img/logo.png'

const injected = injectedModule()
const walletConnect = walletConnectModule({
    projectId: WALLETCONNECT_ID,
    dappUrl: APP_URL,
})

const wallets = [
    injected,
    walletConnect,
]
const appMetadata = {
    name: 'MyShCh.io app',
    icon: app_icon,
    description: 'NFT 2.0 tickets. wNFT gifts. Web 3.0 accesses and certificates',
    recommendedInjectedWallets: [
        {name: 'MetaMask', url: 'https://metamask.io'},
        {name: 'Coinbase', url: 'https://wallet.coinbase.com/'}
    ]
}
const accountCenter: AccountCenterOptions = {
    desktop: {enabled: false},
    mobile: {enabled: false},
}
const web3Onboard = init({
    wallets,
    chains: getChainsForOnboard(),
    appMetadata,
    accountCenter,
})
const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
})
const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
)
root.render(
    <Provider store={store}>
        <SDKProvider>
            <Web3OnboardProvider web3Onboard={web3Onboard}>
                <ThemeProvider theme={darkTheme}>
                    <Root/>
                </ThemeProvider>
            </Web3OnboardProvider>
        </SDKProvider>
    </Provider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
