import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {createBrowserRouter, RouterProvider} from 'react-router-dom'
import {BetApp} from './components/bet'
import {LayoutDefault} from './components/layouts'
import {NotFound} from './components/static'
import {getCurrentNetwork, initializeNetwork, networkChanged} from './store/appSlice'
import {AppDispatch} from './store/store'
import {Settings} from './components/settings'

const App = () => {
    const [lastNetwork, setLastNetwork] = useState<string | null>(null)
    const currentNetwork = useSelector(getCurrentNetwork)

    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        if (lastNetwork) {
            if (lastNetwork !== currentNetwork) {
                dispatch(networkChanged())
            }
        } else if (currentNetwork) {
            dispatch(initializeNetwork())
        }
        setLastNetwork(currentNetwork)
    }, [currentNetwork])

    const router = createBrowserRouter([
        {
            path: '/',
            element: <LayoutDefault/>,
            children: [
                {path: '/', element: <BetApp/>},
                {path: '/settings', element: <Settings/>},
                {path: '*', element: <NotFound/>},
            ],
        },
    ])

    return <RouterProvider router={router}/>

}

export default App
